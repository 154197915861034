import Vue from "vue";
import {
    parse,
    parseISO,
    format,
    getISODay,
    differenceInMilliseconds,
    differenceInSeconds,
    differenceInMinutes,
    differenceInHours,
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    addSeconds,
    addMinutes,
    addHours,
    addDays,
    addMonths,
    addYears,
    subSeconds,
    subMinutes,
    subHours,
    subDays,
    subMonths,
    subYears,
    isBefore,
    isAfter,
    isEqual,
    isSameDay,
    isSameHour,
    isSameMinute,
    isSameMonth,
    isSameYear,
    isSameSecond,
    toDate,
    lastDayOfMonth,
    startOfMonth,
    startOfYear,
} from "date-fns";
import * as duration from "duration-fns";
import * as Locales from "date-fns/locale";

const data = {
    datePatternConfig: {
        serverLocalDate: "dd/MM/yyyy",
        serverLocalDateTime: "dd/MM/yyyy HH:mm:ss",
        fr: {
            localDate: "dd/MM/yyyy",
            localDateTime: "dd/MM/yyyy HH:mm:ss",
            full: "EEE dd MMM yyyy",
            fullWithTime: "EEE dd MMM yyyy - HH'h'mm",
            localDateWithTime: "dd/MM/yyyy HH'h'mm",
            time: "HH'h'mm",
        },
        it: {
            localDate: "dd/MM/yyyy",
            localDateTime: "dd/MM/yyyy HH:mm:ss",
            full: "EEE dd MMM yyyy",
            fullWithTime: "EEE dd MMM yyyy - HH'h'mm",
            localDateWithTime: "dd/MM/yyyy HH'h'mm",
            time: "HH'h'mm",
        },
        en: {
            localDate: "yyyy-MM-dd",
            localDateTime: "yyyy-MM-dd HH:mm:ss",
            full: "EEE dd MMM yyyy",
            fullWithTime: "EEE dd MMM yyyy - HH'h'mm",
            time: "HH:mm",
        },
    },
    decimalFormatDefaults: {
        style: "decimal",
        locale: "fr-FR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        vary: false,
    },
    percentFormatDefaults: {
        style: "percent",
        locale: "fr-FR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        vary: false,
    },
    currencyFormatDefaults: {
        style: "currency",
        locale: "fr-FR",
        currency: "EUR",
        currencyDisplay: "symbol",
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        vary: false,
    },
};

const methods = {
        _locale: Locales.fr,

        setLocale(locale) {
            this._locale = locale;
        },
        /*
         *  String methods
         */
        capitalize(value) {
            if (!value) return "";
            return value.toString().charAt(0).toUpperCase() + value.slice(1);
        },

        /*
         *  Number methods
         */
        formatDecimal(value, options) {
            if (value === undefined || value === null) return "";
            const opts = {...data.decimalFormatDefaults, ...options};

            let sign = opts.vary && value > 0 ? "+" : "";
            return sign + value.toLocaleString(opts.locale, opts);
        },
        formatPercent(value, options) {
            if (value === undefined || value === null) return "";
            const opts = {...data.percentFormatDefaults, ...options};

            let sign = opts.vary && value > 0 ? "+" : "";
            return sign + value.toLocaleString(opts.locale, opts);
        },
        formatCurrency(value, options) {
            if (value === undefined || value === null) return "";
            const opts = {...data.currencyFormatDefaults, ...options};

            let sign = opts.vary && value > 0 ? "+" : "";
            return sign + value.toLocaleString(opts.locale, opts);
        },

        /*
         *  Dates methods
         */
        toDate(date) {
            return toDate(date);
        },
        parseDateISO(dateStr) {
            return parseISO(dateStr)
        },
        parseDate(dateStr, pattern) {
            const date = parse(dateStr, pattern, new Date());
            return date;
        },
        formatDate(date, pattern) {
            if (!date) return null;

            const locale = this._locale || Locales.fr;
            return format(date, pattern, {locale: locale});
        },
        parseAndFormatDate(value, parsePattern, formatPattern) {
            if (!value) return null;
            return this.formatDate(this.parseDate(value, parsePattern), formatPattern);
        },
        weekDay(date) {
            return getISODay(date);
        },
        getMonth(date) {
            return this.formatDate(date, "M");
        },
        getYear(date) {
            return this.formatDate(date, "Y");
        },
        durationTime(earlierDate, laterDate, formatPattern) {
            if (!this.isValidDate(earlierDate) || !this.isValidDate(laterDate))
                throw "Date-fns can only parse Date instance";

            return this.formatDate(
                addSeconds(new Date(0), differenceInSeconds(laterDate, earlierDate)),
                formatPattern || "HH:mm:ss"
            );
        },
        renderDuration(dur) {
            let m = duration.parse(dur);
            let str = "";

            if (m.years > 0) str += parseInt(m.years) + "ans";
            if (m.months > 0) str += parseInt(m.months) + "mois";
            if (m.weeks > 0) str += parseInt(m.weeks) + "semaines";
            if (m.days > 0) str += parseInt(m.days) + "j";
            if (m.hours > 0) str += parseInt(m.hours) + "h";
            if (m.minutes > 0) str += parseInt(m.minutes) + "mn";
            if (m.seconds > 0) str = m.seconds + "s";
            if (m.milliseconds > 0) str = m.seconds + "ms";

            return str;
        },
        duration(earlierDate, laterDate, unit) {
            if (!this.isValidDate(earlierDate) || !this.isValidDate(laterDate))
                return 0;

            switch (unit) {
                case "second":
                case "seconds":
                    return differenceInSeconds(laterDate, earlierDate);
                case "minute":
                case "minutes":
                    return differenceInMinutes(laterDate, earlierDate);
                case "hour":
                case "hours":
                    return differenceInHours(laterDate, earlierDate);
                case "day":
                case "days":
                    return differenceInDays(laterDate, earlierDate);
                case "month":
                case "months":
                    return differenceInMonths(laterDate, earlierDate);
                case "year":
                case "years":
                    return differenceInYears(laterDate, earlierDate);
                default:
                    return differenceInMilliseconds(laterDate, earlierDate);
            }
        },
        durationFormatted(start, stop, format) {
            const timeUnit = "milliseconds";
            return methods.formatDate(methods.duration(start, stop, timeUnit), format);
        },
        nbNights(earlierDate, laterDate) {
            return this.duration(earlierDate, laterDate, "day");
        },
        nbDays(earlierDate, laterDate) {
            return this.duration(earlierDate, laterDate, "day") + 1;
        },
        nbHours(earlierDate, laterDate) {
            return this.duration(earlierDate, laterDate, "hours");
        },
        nbMinutes(earlierDate, laterDate) {
            return this.duration(earlierDate, laterDate, "minutes");
        },
        nbSeconds(earlierDate, laterDate) {
            return this.duration(earlierDate, laterDate, "seconds");
        },
        age(birthday, atDate, birthdayPattern) {
            const p = birthdayPattern || data.datePatternConfig.serverLocalDate;
            const birthdayDate = this.parseDate(birthday, p);
            const aD = atDate || new Date();
            return this.duration(birthdayDate, aD, "year");
        },
        isValidDate(d) {
            return d instanceof Date && !isNaN(d);
        },
        addToDate(date, nb, unit) {
            if (!this.isValidDate(date)) return 0;

            switch (unit) {
                case "second":
                case "seconds":
                    return addSeconds(date, nb);
                case "minute":
                case "minutes":
                    return addMinutes(date, nb);
                case "hour":
                case "hours":
                    return addHours(date, nb);
                case "day":
                case "days":
                    return addDays(date, nb);
                case "month":
                case "months":
                    return addMonths(date, nb);
                case "year":
                case "years":
                    return addYears(date, nb);
                default:
                    return date;
            }
        },
        subToDate(date, nb, unit) {
            if (!this.isValidDate(date)) return 0;

            switch (unit) {
                case "second" || "seconds":
                    return subSeconds(date, nb);
                case "minute" || "minutes":
                    return subMinutes(date, nb);
                case "hour" || "hours":
                    return subHours(date, nb);
                case "day" || "days":
                    return subDays(date, nb);
                case "month" || "months":
                    return subMonths(date, nb);
                case "year" || "years":
                    return subYears(date, nb);
            }
        },
        checkIsBefore(dateA, dateB) {
            return isBefore(dateA, dateB);
        },
        checkIsAfter(dateA, dateB) {
            return isAfter(dateA, dateB);
        },
        isSameOrAfter(dateA, dateB) {
            return isEqual(dateA, dateB) ? true : isAfter(dateA, dateB) ? true : false;
        },
        isSameOrBefore(dateA, dateB) {
            return isEqual(dateA, dateB) ? true : isBefore(dateA, dateB) ? true : false;
        },
        isSame(dateA, dateB, unit) {
            switch (unit) {
                case "year":
                case "years":
                    return isSameYear(dateA, dateB);

                case "month":
                case "months":
                    return isSameMonth(dateA, dateB);

                case "day":
                case "days":
                    return isSameDay(dateA, dateB);

                case "hour":
                case "hours":
                    return isSameHour(dateA, dateB);

                case "minute":
                case "minutes":
                    return isSameMinute(dateA, dateB);

                case "second":
                case "seconds":
                    return isSameSecond(dateA, dateB);

                default:
                    console.log(`${unit} is not a valid unit`);
                    return isSameSecond(dateA, dateB);
            }
        },
        isTomorrow(start, stop) {
            return this.duration(
                this.formatDate(start, this.serverLocalDateTime),
                this.formatDate(stop, this.serverLocalDateTime),
                "days"
            );
        },
        /*
         * Prestations methods
         */
        prestationDatePattern(datetime, localDatePattern, localDatetimePattern) {
            if (datetime == null || datetime == "") {
                throw "Parameter for prestationDatePatterns is null or empty";
            } else {
                return datetime.endsWith("00:00:00")
                    ? localDatePattern
                    : localDatetimePattern;
            }
        },

        /*
         * DataTable methods
         */
        sortDates(a, b, pattern) {
            return methods.parseDate(a, pattern).getTime() -
            methods.parseDate(b, pattern).getTime() <
            0
                ? -1
                : 1;
        },

        /*
         * Return the last day of the date's month
         */
        getLastMonthDay(date) {
            return lastDayOfMonth(date);
        },
        firstDayOfTheMonth(date) {
            return startOfMonth(date);
        },
        firstDayOfTheYear(date) {
            return startOfYear(date);
        },
        /*
         * Check device
         */

        isMobile() {
            return this.getDevice() == "mobile";
        },
        isTablet() {
            return this.getDevice() == "tablet";
        },
        isDesktop() {
            return this.getDevice() == "desktop";
        },

        getDevice() {
            const width = window.innerWidth;

            if (width < 768) return "mobile";
            else if (width >= 768 && width <= 1024) return "tablet";
            else return "desktop";
        },
        firstLetterUpper(s) {
            return s == null || s.trim().length == 0
                ? ""
                : s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase();
        },

        /*
         * DashBoard Utils
         */
        calculateDates(date) {
            let data = {type: null, start: null, stop: null, dayOffset: null}
            switch (date.type) {
                case 'fixed':
                    data.type = date.type
                    data.start = new Date(date.start)
                    data.stop = new Date(date.stop != null ? date.stop : date.start)
                    break;
                case 'calculated':
                    data.type = date.type
                    data.start = this.addToDate(date.start != null ? new Date(date.start) : new Date(), date.dayOffset, "day")
                    data.stop = this.addToDate(date.stop != null ? new Date(date.stop) : new Date(), date.dayOffset, "day")
                    break;
                default:
                    return data
            }

            data.start = format(data.start, 'yyyy-MM-dd')
            data.stop = format(data.stop, 'yyyy-MM-dd')
            return data
        },

        formatPayloadWidgetDashboard(conf, form) {
            let periods = {act: methods.calculateDates(conf.data.act), old: methods.calculateDates(conf.data.old)}

            if (periods.old.start == null && periods.act.type != null) {
                periods.old.start = this.formatDate(subYears(this.parseDate(periods.act.start, "yyyy-MM-dd"), 1), "yyyy-MM-dd")
                periods.old.stop = this.formatDate(subYears(this.parseDate(periods.act.stop, "yyyy-MM-dd"), 1), "yyyy-MM-dd")
            }

            const formProps = JSON.parse(JSON.stringify(form))
            const searchForm = {
                startDateAct: periods.act.start != null ? periods.act.start : formProps.startDateAct,
                stopDateAct: periods.act.stop != null ? periods.act.stop : formProps.stopDateAct,
                startDateOld: periods.old.start != null ? periods.old.start : formProps.startDateOld,
                stopDateOld: periods.old.stop != null ? periods.old.stop : formProps.stopDateOld,
                destinations: conf.data.destinations.length > 0 ? conf.data.destinations : formProps.destinations,
                sites: conf.data.sites.length > 0 ? conf.data.sites : formProps.sites,
                commercialIds: [],
                allAgencies: false
            }
            return searchForm
        },

        getHotelissimaImportantDates() {
           return [
                {date: '2023-11-30', name: "Changement marge Hotelissima (1.08)",},
                {date: "2023-12-18", name: "Couture pub hôtel seul: Maurice Seychelles Maldives"},
                {date: "2024-01-15", name: "Cyclone Belal"},
                {date: "2024-03-14", name: "Zil v2"},
                {date: "2024-05-23", name: "Pledg Front"},
                {date: "2025-02-26", name: "Cyclone Garance"},
                {date: "2025-04-07", name: "Chikungunya 25"},
            ]


        },
    }
;

Vue.mixin({
    data: () => data,
    computed: {
        _datePattern() {
            return this.datePatternConfig[this.$locale];
        },
    },
    methods: {
        setLocale(localeCode) {
            const locale = Locales[localeCode] || Locales.fr;
            methods.setLocale(locale);
            return locale;
        },

        /*
         *  String methods
         */
        _capitalize(value) {
            return methods.capitalize(value);
        },

        /*
         *  Number methods
         */
        _formatDecimal(value, options) {
            return methods.formatDecimal(value, options);
        },
        _formatPercent(value, options) {
            return methods.formatCurrency(value, options);
        },
        _formatCurrency(value, currency, options) {
            return methods.formatCurrency(value, {
                ...options,
                ...{currency: currency},
            });
        },

        /*
         *  Returns Js Date object
         */
        _now() {
            return new Date();
        },
        /*
         * Returns current date formatted with `pattern` or `dd/MM/yyyy HH:mm:ss`
         */

        _nowFormatted(pattern) {
            const p = pattern || this._datePattern.full;
            return methods.formatDate(new Date(), p);
        },
        /**
         * Parse a String to a Date object using `pattern`
         * Default pattern is LocalDate
         */
        _toDate(date) {
            return methods.toDate(date);
        },
        _parseDateISO(dateStr) {
            return methods.parseDateISO(dateStr)
        },
        _parseDate(dateStr, pattern) {
            const p = pattern || this.datePatternConfig.serverLocalDate;
            return methods.parseDate(dateStr, p);
        },

        /**
         * Parse a String to a Date object using `pattern`
         * Default pattern is LocalDateTime
         */
        _parseDateTime(dateStr, pattern) {
            const p = pattern || this.datePatternConfig.serverLocalDateTime;
            return methods.parseDate(dateStr, p);
        },

        /**
         * Format a Date object to String using `pattern`
         * Default pattern `EEE dd MMM yyyy`
         */
        _formatDate(date, pattern) {
            if (!date) return "";
            const p = pattern || this._datePattern.full;
            return methods.formatDate(date, p);
        },

        /**
         * Parse a String to a Date object using `parsePattern` then
         * Format Date object to String using `formatPattern`
         */
        _parseAndFormatDate(value, parsePattern, formatPattern) {
            return methods.parseAndFormatDate(value, parsePattern, formatPattern);
        },

        /**
         * Parse a `dd/MM/yyyy HH:mm:ss` Then
         * Format  to String with `pattern`
         * Default pattern is `EEE dd MMM yyyy`
         */
        _parseAndFormatLocalDateTime(dateStr, pattern) {
            const p = pattern || this._datePattern.full;
            return methods.parseAndFormatDate(
                dateStr,
                this.datePatternConfig.serverLocalDateTime,
                p
            );
        },

        _differenceInMonths(dateLeft, dateRight) {
            return differenceInMonths(dateLeft, dateRight);
        },

        _renderDuration(dur) {
            return methods.renderDuration(dur);
        },
        _weekDay(date) {
            return methods.weekDay(date);
        },
        _getMonth(date) {
            return methods.getMonth(date);
        },
        _getYear(date) {
            return methods.getYear(date);
        },
        _durationTime(date1, date2, formatPattern) {
            return methods.durationTime(date1, date2, formatPattern);
        },
        _duration(date1, date2, unit) {
            return methods.duration(date1, date2, unit);
        },
        _durationFormatted(start, stop, format) {
            return methods.durationFormatted(start, stop, format);
        },
        _durationBeforeStart(start) {
            const startDate = methods.parseDate(
                start,
                this.datePatternConfig.serverLocalDateTime
            );
            const days = methods.duration(startDate, new Date(), "days");

            if (days > 0) return "-" + days;
            else return "+" + Math.abs(days);
        },
        _nbNightsFormatted(date1, date2, dateFormat) {
            const format = dateFormat || this.datePatternConfig.serverLocalDateTime;
            return methods.nbNights(
                methods.parseDate(date1, format),
                methods.parseDate(date2, format)
            );
        },
        _nbNights(date1, date2) {
            return methods.nbNights(date1, date2);
        },
        _nbDays(date1, date2) {
            return methods.nbDays(date1, date2);
        },
        _age(birthday, atDate, birthdayPattern) {
            return methods.age(birthday, atDate, birthdayPattern);
        },
        _addDays(date, nbDays) {
            return methods.addToDate(date, nbDays, "day");
        },
        _subDays(date, nbDays) {
            return methods.subToDate(date, nbDays, "day");
        },
        _subYears(date, nbYears) {
            return methods.subToDate(date, nbYears, "year");
        },
        _addYears(date, nbYears) {
            return methods.addToDate(date, nbYears, "years");
        },
        _addMonths(date, nbMonths) {
            return methods.addToDate(date, nbMonths, "months");
        },
        _isMajor(birthdate, startDate) {
            startDate = startDate ? startDate : this._now();
            let age = methods.age(birthdate, startDate);

            return age >= 18;
        },
        _isBefore(date, dateToCompare) {
            return methods.checkIsBefore(date, dateToCompare);
        },
        _isAfter(date, dateToCompare) {
            return methods.checkIsAfter(date, dateToCompare);
        },
        _isSameOrAfter(date, dateToCompare) {
            return methods.isSameOrAfter(date, dateToCompare);
        },
        _isSameOrBefore(date, dateToCompare) {
            return methods.isSameOrBefore(date, dateToCompare);
        },
        _isSame(date, dateToCompare, unit) {
            return methods.isSame(date, dateToCompare, unit);
        },
        _isBetween(dateStart, dateStop, dateToTest) {
            return (
                methods.isSameOrAfter(dateToTest, dateStart) &&
                methods.isSameOrBefore(dateToTest, dateStop)
            );
        },
        _isTomorrow(start, stop) {
            return methods.isTomorrow(start, stop);
        },
        _percentage(first,second){
            if(first == 0 && second == 0){
                return 0
            }
            return (second/first)* 100;
        },
        _percentEvolution(first, second) {
            if(first == 0 && second == 0){
                return 0
            }
            return ((second - first) / first) * 100;
        },
        _formatEvolutionPercent(first,second){
            return this._formatDecimal(this._percentEvolution(first, second)) + "%";
        },
        /*
         * Prestations methods
         */
        _prestationDatePattern(datetime) {
            return methods.prestationDatePattern(
                datetime,
                this._datePattern.localDate,
                this._datePattern.localDateTime
            );
        },

        /*
         * DataTable methods
         */
        _sortDates(a, b, pattern) {
            return methods.sortDates(a, b, pattern);
        },

        _getLastMonthDay(date) {
            return methods.getLastMonthDay(date);
        },
        _getFirstMonthDay(date) {
            return methods.firstDayOfTheMonth(date);
        },
        _getFirstYearDay(date) {
            return methods.firstDayOfTheYear(date);
        },
        /*
         * Check device
         */
        _isMobile() {
            return methods.isMobile();
        },
        _isTablet() {
            return methods.isTablet();
        },
        _isDesktop() {
            return methods.isDesktop();
        },

        _getDevice() {
            return methods.getDevice();
        },
        _firstLetterUpper(s) {
            return methods.firstLetterUpper(s);
        },
        _calculateDates(date) {
            return methods.calculateDates(date)
        },
        _formatPayloadWidgetDashboard(conf, form) {
            return methods.formatPayloadWidgetDashboard(conf, form)
        },
        _getHotelissimaImportantDates(minDate, maxDate) {
            const dates= methods.getHotelissimaImportantDates()
            return dates.filter(day => this._isBetween(new Date(minDate), new Date(maxDate), new Date(day.date)))
        }
    },
    filters: {
        capitalize(value) {
            if (!value) return "";
            return value.toString().charAt(0).toUpperCase() + value.slice(1);
        },
        uppercase(value) {
            if (!value) return "";
            return value.toString().toUpperCase();
        },
        lowercase(value) {
            if (!value) return "";
            return value.toString().toUpperCase();
        },
        decimal(value, options) {
            return methods.formatDecimal(value, options);
        },
        percent(value, options) {
            return methods.formatPercent(value, options);
        },
        currency(value, currency, options) {
            return methods.formatCurrency(value, {
                ...options,
                ...{currency: currency},
            });
        },
        parseDateISO(value) {
            return methods.parseDateISO(value);
        },
        parseDate(value, pattern) {
            return methods.parseDate(value, pattern);
        },
        formatDate(value, pattern) {
            return methods.formatDate(value, pattern);
        },
        parseAndFormatDate(value, parsePattern, formatPattern) {
            return methods.parseAndFormatDate(value, parsePattern, formatPattern);
        },
        duration(value, toDate, unit) {
            return methods.duration(value, toDate, unit);
        },
        nbNights(value, toDate) {
            return methods.nbNights(value, toDate);
        },
        nbDays(value, toDate) {
            return methods.nbDays(value, toDate);
        },
        age(value, atDate, birthdayPattern) {
            return methods.age(value, atDate, birthdayPattern);
        },
    },
});
